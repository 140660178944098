/* eslint-disable @typescript-eslint/no-misused-promises */
import './PlaylistConverter.css';
import ProvidersConfig from '../../api/ProvidersConfig';
import type Provider from '../../api/providers/Provider';
import { useEffect, useRef, useState } from 'react';
import { type Playlist, type Track } from '../../api/providers/Types';
import MessageStack, {
  MessageStackHandles,
} from '../../components/MessageStack/MessageStack';
import CsvImport from '../../components/CsvImport/CsvImport';

const PlaylistConverter = (): JSX.Element => {
  const [fromMusicService, setFromMusicService] = useState<string>('');
  const [toMusicService, setToMusicService] = useState<string>('');
  const [musicProviders, setMusicProviders] = useState<Provider[]>([]);
  const [playlists, setPlaylists] = useState<Playlist[]>([]);
  const [selectedPlaylistId, setSelectedPlaylistId] = useState<string>('');
  const [loadingMessageId, setLoadingMessageId] = useState<number>(0);
  const messageStackRef = useRef<MessageStackHandles>(null);
  
  // New state for playlist creation
  const [newPlaylistName, setNewPlaylistName] = useState<string>('');
  const [newPlaylistDescription, setNewPlaylistDescription] = useState<string>('');
  const [newPlaylistTracks, setNewPlaylistTracks] = useState<Track[]>([]);
  const [selectedProvider, setSelectedProvider] = useState<string>('');

  useEffect(() => {
    const connectedProviders = ProvidersConfig.getConnectedProviders();
    if (connectedProviders.length < 2) {
      messageStackRef.current?.addMessage({
        message:
          'You need to connect at least two music services to use this feature',
        type: 'error',
        dismissable: true,
        route: '/settings',
      });
      return;
    }
    setMusicProviders([...connectedProviders]);
    if (connectedProviders.length > 0) {
      setFromMusicService(connectedProviders[0].prefix);
      if (connectedProviders.length > 1) {
        setToMusicService(connectedProviders[1].prefix);
      }
    }
  }, []);

  // Fetch and set playlists for the fromMusicService
  useEffect(() => {
    const fetchPlaylists = async (): Promise<void> => {
      setPlaylists([]); // Clear playlists when switching providers
      setSelectedPlaylistId(''); // Reset selection
      
      const provider = ProvidersConfig.musicProviders.get(fromMusicService);
      if (provider != null) {
        try {
          // Show loading message
          const msgId = Date.now();
          setLoadingMessageId(msgId);
          messageStackRef.current?.addMessage({
            message: `Fetching playlists from ${provider.name}...`,
            type: 'info',
            dismissable: true,
          });
          
          const playlists = await provider.getPlaylists();
          
          // Remove previous messages
          messageStackRef.current?.removeMessage(loadingMessageId);
          
          // Ensure playlists is an array before processing it
          if (Array.isArray(playlists) && playlists.length > 0) {
            setPlaylists(playlists);
            setSelectedPlaylistId(playlists[0].id);
          } else {
            // Handle case where playlists is empty or not an array
            messageStackRef.current?.addMessage({
              message: `No playlists found for ${provider.name}`,
              type: 'info',
              dismissable: true,
            });
          }
        } catch (error) {
          // Handle the error directly here instead of rethrowing
          const errorMsg = error instanceof Error ? error.message : String(error);
          console.error(`Playlist fetch error for ${provider.name}:`, error);
          const serviceType = fromMusicService.includes('spotify') ? 'Spotify' : 
            fromMusicService.includes('youtube') ? 'YouTube' : 
              provider.name;
          
          // Create user-friendly error message
          let friendlyMessage = `Error fetching playlists from ${serviceType}`;
          
          if (errorMsg.includes('timed out') || errorMsg.includes('500')) {
            friendlyMessage = `${serviceType} API is experiencing issues (timeout/server error). Please try again later.`;
          } else if (errorMsg.includes('undefined') && errorMsg.includes('map')) {
            friendlyMessage = `Failed to retrieve playlists from ${serviceType}. Please verify your account connection.`;
          }
          
          messageStackRef.current?.addMessage({
            message: friendlyMessage,
            type: 'error',
            dismissable: true,
            route: '/settings',
          });
        }
      }
    };

    if (fromMusicService !== '') {
      fetchPlaylists().catch((error) => {
        // This should only run if there's an unexpected error in fetchPlaylists itself
        console.error('Unexpected error in fetchPlaylists:', error);
        handleProviderError({
          message: 'An unexpected error occurred while fetching playlists'
        });
      });
    }
  }, [fromMusicService]);

  // Handle errors from the music providers
  const handleProviderError = (error: { message: string }) => {
    let customMessage = '';
    console.error('Provider error:', error);

    // Check for JSON parsing errors which typically indicate auth issues
    if (
      error.message.includes('Unexpected token') &&
      error.message.includes('<!doctype')
    ) {
      if (error.message.includes('spotify')) {
        customMessage =
          'Your Spotify authentication has expired. Please reconnect your Spotify account in "Add music services".';
      } else if (error.message.includes('youtube')) {
        customMessage =
          'Your YouTube Music authentication has expired. Please reconnect your account in "Add music services".';
      } else {
        customMessage =
          'Authentication error. Please reconnect your music service in "Add music services".';
      }
    } else if (error.message.includes('https://developers.google.com')) {
      customMessage =
        'Reset your YouTube Music connexion in "Add music services". If it does not work, contact me (info in the page footer).';
    } else if (error.message.includes('spotify.com')) {
      customMessage =
        'Reset your Spotify connexion in "Add music services". If it does not work, contact me (info in the page footer).';
    } else {
      customMessage = error.message;
    }

    messageStackRef.current?.addMessage({
      message: customMessage,
      type: 'error',
      dismissable: true,
      route: '/settings', // Add a route to the settings page for reconnection
    });
  };

  // Populate the select options with the available music providers
  const populateSelectOptions = (): JSX.Element[] => {
    return musicProviders.map((provider) => (
      <option key={provider.prefix} value={provider.prefix}>
        {provider.name}
      </option>
    ));
  };

  const handleFromMusicServiceChange = (
    e: React.ChangeEvent<HTMLSelectElement>
  ): void => {
    const newFromService = e.target.value;
    setFromMusicService(newFromService);
    setPlaylists([]);

    // Automatically adjust "to" service to avoid matching "from" service
    if (newFromService === toMusicService) {
      const nextService = musicProviders.find(
        (provider) => provider.prefix !== newFromService
      );
      if (nextService != null) setToMusicService(nextService.prefix);
    }
  };

  const handleToMusicServiceChange = (
    e: React.ChangeEvent<HTMLSelectElement>
  ): void => {
    setToMusicService(e.target.value);
  };

  const convertPlaylist = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): Promise<void> => {
    e.preventDefault();
    if (
      fromMusicService === '' ||
      toMusicService === '' ||
      selectedPlaylistId === ''
    ) {
      messageStackRef.current?.addMessage({
        message: 'Missing required fields',
        type: 'error',
        dismissable: true,
      });
      return;
    }
    const from = ProvidersConfig.musicProviders.get(fromMusicService);
    const to = ProvidersConfig.musicProviders.get(toMusicService);
    if (from === undefined || to === undefined) {
      messageStackRef.current?.addMessage({
        message: 'Invalid music service',
        type: 'error',
        dismissable: true,
      });
      return;
    }

    try {
      const playlist = await from.getPlaylist(selectedPlaylistId);
      if (playlist === undefined) {
        throw new Error(`Error fetching playlist from ${from.name}`);
      }

      await to.createPlaylist(playlist);

      messageStackRef.current?.addMessage({
        message: `Successfully converted "${playlist.name}"`,
        type: 'success',
        dismissable: true,
      });
    } catch (error: unknown) {
      console.error(`Error converting playlist from ${from?.name} to ${to?.name}:`, error);
      handleProviderError(error as { message: string });
    }
  };

  const handlePlaylistChange = (
    e: React.ChangeEvent<HTMLSelectElement>
  ): void => {
    setSelectedPlaylistId(e.target.value);
  };

  const handleCreatePlaylist = async (e: React.FormEvent): Promise<void> => {
    e.preventDefault();
    
    if (!selectedProvider || !newPlaylistName || newPlaylistTracks.length === 0) {
      messageStackRef.current?.addMessage({
        message: 'Please fill in all required fields and import a CSV file',
        type: 'error',
        dismissable: true,
      });
      return;
    }

    const provider = ProvidersConfig.musicProviders.get(selectedProvider);
    if (provider == null) {
      messageStackRef.current?.addMessage({
        message: 'Invalid music service',
        type: 'error',
        dismissable: true,
      });
      return;
    }

    try {
      const playlist: Playlist = {
        id: '', // Will be assigned by the provider
        name: newPlaylistName,
        description: newPlaylistDescription,
        tracks: newPlaylistTracks,
      };

      const createdPlaylist = await provider.createPlaylist(playlist);
      
      if (createdPlaylist != null) {
        messageStackRef.current?.addMessage({
          message: `Successfully created playlist "${createdPlaylist.name}"`,
          type: 'success',
          dismissable: true,
        });
        
        // Reset form
        setNewPlaylistName('');
        setNewPlaylistDescription('');
        setNewPlaylistTracks([]);
        setSelectedProvider('');
      }
    } catch (error) {
      console.error('Error creating playlist:', error);
      handleProviderError(error as { message: string });
    }
  };

  const handleTracksLoaded = (tracks: Track[]): void => {
    setNewPlaylistTracks(tracks);
  };

  return (
    <div>
      <MessageStack ref={messageStackRef} />
      
      {/* Existing playlist conversion form */}
      <h1>Convert playlists</h1>
      <form
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <fieldset>
          <label htmlFor="fromMusicService">From</label>
          <select
            id="fromMusicService"
            aria-label="Origin Music Service"
            value={fromMusicService}
            onChange={handleFromMusicServiceChange}
          >
            {populateSelectOptions()}
          </select>
          <label htmlFor="toMusicService">to</label>
          <select
            id="toMusicService"
            aria-label="Destination Music Service"
            value={toMusicService}
            onChange={handleToMusicServiceChange}
          >
            {populateSelectOptions()}
          </select>
          <br />
          <br />
          <label htmlFor="toMusicService">Select a playlist: </label>
          <select
            id="playlistSelector"
            aria-label="Playlist Selector"
            value={selectedPlaylistId}
            onChange={handlePlaylistChange}
          >
            {playlists.map((playlist) => (
              <option key={playlist.name} value={playlist.id}>
                {playlist.name}
              </option>
            ))}
          </select>
          <br />
          <br />
          <button id="convertBtn" type="submit" onClick={convertPlaylist}>
            Convert
          </button>
        </fieldset>
      </form>

      {/* New playlist creation form */}
      <h2>Create playlist from CSV</h2>
      <form onSubmit={handleCreatePlaylist}>
        <fieldset>
          <label htmlFor="newPlaylistName">Playlist Name:</label>
          <input
            type="text"
            id="newPlaylistName"
            value={newPlaylistName}
            onChange={(e) => setNewPlaylistName(e.target.value)}
            required
          />
          
          <label htmlFor="newPlaylistDescription">Description (optional):</label>
          <input
            type="text"
            id="newPlaylistDescription"
            value={newPlaylistDescription}
            onChange={(e) => setNewPlaylistDescription(e.target.value)}
          />
          
          <label htmlFor="selectedProvider">Select Platform:</label>
          <select
            id="selectedProvider"
            value={selectedProvider}
            onChange={(e) => setSelectedProvider(e.target.value)}
            required
          >
            <option value="">Select a platform</option>
            {populateSelectOptions()}
          </select>

          <CsvImport onTracksLoaded={handleTracksLoaded} />
          
          <button type="submit">Create Playlist</button>
        </fieldset>
      </form>
    </div>
  );
};

export default PlaylistConverter;
