import ProvidersConfig from '../ProvidersConfig';
import type Provider from './Provider';
import { type Track, type Playlist } from './Types';

// Cache for YouTube Music category ID
let musicCategoryIdCache = '';

// Retry configuration
const MAX_RETRIES = 3;
const RETRY_DELAY = 1000;

/**
 * Retries a fetch operation with exponential backoff on rate limit errors
 * @param fetchOperation The fetch operation to retry
 * @param retryCount Current retry count
 * @returns Response from the API
 */
async function retryableFetch(fetchOperation: () => Promise<Response>, retryCount = 0): Promise<Response> {
  try {
    const response = await fetchOperation();
    const data = await response.json();
    
    if (!response.ok) {
      // Check for rate limit error (429) and retry if we haven't reached max retries
      if (response.status === 429 && retryCount < MAX_RETRIES) {
        const delay = RETRY_DELAY * Math.pow(2, retryCount);
        console.warn(`Rate limited, retrying in ${delay}ms...`);
        await new Promise(resolve => setTimeout(resolve, delay));
        return retryableFetch(fetchOperation, retryCount + 1);
      }
      
      throw new Error(String(data.error?.message || 'API error'));
    }
    
    return { ...response, json: () => Promise.resolve(data) } as Response;
  } catch (error) {
    if (retryCount < MAX_RETRIES) {
      const delay = RETRY_DELAY * Math.pow(2, retryCount);
      console.warn(`Error occurred, retrying in ${delay}ms...`, error);
      await new Promise(resolve => setTimeout(resolve, delay));
      return retryableFetch(fetchOperation, retryCount + 1);
    }
    throw error;
  }
}

async function youtubeGetMusicCategoryId (): Promise<string> {
  try {
    // Return cached value if available
    if (musicCategoryIdCache !== '') {
      return musicCategoryIdCache;
    }

    const response = await retryableFetch(() => 
      fetch('https://www.googleapis.com/youtube/v3/videoCategories?part=snippet&regionCode=US', {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${ProvidersConfig.getAccessToken(YoutubeMusic.prefix)}`
        }
      })
    );
    
    const data = await response.json();
    const musicCategory = data.items.find((item: { snippet: { title: string } }) => item.snippet.title === 'Music');
    
    // Cache the result
    musicCategoryIdCache = musicCategory.id.toString();
    return musicCategoryIdCache;
  } catch (error: unknown) {
    throw new Error(`${String(error)} (fetching YouTube Music category ID failed)`);
  }
}

const YoutubeMusic: Provider = {
  name: 'YouTube Music',
  prefix: 'youtube_music',

  async authenticate (): Promise<void> { },

  async fetchAccessToken (): Promise<void> { },

  async refreshAccessToken (): Promise<void> { },

  async getPlaylists (): Promise<Playlist[]> {
    try {
      const playlists: Playlist[] = [];
      let nextPageToken: string | undefined;
      
      // Use pagination to fetch all playlists
      do {
        const pageUrl = 'https://www.googleapis.com/youtube/v3/playlists?part=snippet&mine=true&maxResults=50' + 
                      (nextPageToken ? `&pageToken=${nextPageToken}` : '');
        
        const response = await retryableFetch(() => 
          fetch(pageUrl, {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${ProvidersConfig.getAccessToken(this.prefix)}`
            }
          })
        );

        const data = await response.json();
        
        // Add playlists from current page
        const pagePlaylists = data.items.map((playlist: { snippet: { title: string }, id: string }) => ({
          name: playlist.snippet.title,
          id: playlist.id
        }));
        
        playlists.push(...pagePlaylists);
        
        // Get next page token for pagination
        nextPageToken = data.nextPageToken;
      } while (nextPageToken);
      
      return playlists;
    } catch (error) {
      throw new Error(`${String(error)} (fetching YouTube Music playlists failed)`);
    }
  },

  clean (): void {
    localStorage.removeItem(`${this.prefix}_access_token`);
    // Also clear cache when cleaning up
    musicCategoryIdCache = '';
  },

  async getPlaylist (id: string): Promise<Playlist | undefined> {
    let playlist: Playlist | undefined;
    try {
      const response = await retryableFetch(() => 
        fetch('GET https://www.googleapis.com/youtube/v3/playlists' +
        '?part=snippet' +
        `&id=${encodeURIComponent(id)}` +
        '&maxResults=1',
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${ProvidersConfig.getAccessToken(this.prefix)}`
          }
        })
      );

      const data = await response.json();
      playlist = {
        id: data.external_urls.spotify,
        name: data.name,
        description: data.description,
        tracks: []
      };
    } catch (error) {
      throw new Error(`${String(error)} (fetching YouTube Music playlist failed)`);
    }
    
    try {
      const tracks: Track[] = [];
      let nextPageToken: string | undefined;
      
      // Use pagination to fetch all playlist items
      do {
        const pageUrl = 'https://www.googleapis.com/youtube/v3/playlistItems' +
                      '?part=snippet' +
                      `&playlistId=${encodeURIComponent(id)}` +
                      '&maxResults=50' +
                      (nextPageToken ? `&pageToken=${nextPageToken}` : '');
        
        const response = await retryableFetch(() => 
          fetch(pageUrl, {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${ProvidersConfig.getAccessToken(this.prefix)}`
            }
          })
        );

        const data = await response.json();
        
        // Add tracks from current page
        const pageTracks = data.items.map((track: { snippet: { title: string, videoOwnerChannelTitle: string } }) => ({
          name: track.snippet.title,
          artist: track.snippet.videoOwnerChannelTitle
        }));
        
        tracks.push(...pageTracks);
        
        // Get next page token for pagination
        nextPageToken = data.nextPageToken;
      } while (nextPageToken);

      if (playlist) {
        playlist.tracks = tracks;
      }
      
      console.log(playlist);
      return playlist;
    } catch (error) {
      throw new Error(`${String(error)} (fetching YouTube Music playlist tracks failed)`);
    }
  },

  async createPlaylist (playlist: Playlist): Promise<Playlist | undefined> {
    let newPlaylist: Playlist | undefined;
    try {
      // Create playlist
      const response = await retryableFetch(() => 
        fetch('https://www.googleapis.com/youtube/v3/playlists?part=snippet,status', {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${ProvidersConfig.getAccessToken(this.prefix)}`,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            snippet: {
              title: playlist.name,
              description: playlist.description
            },
            status: {
              privacyStatus: 'private'
            }
          })
        })
      );
      
      const data = await response.json();
      newPlaylist = {
        name: data.snippet.title,
        id: data.id,
        description: data.snippet.description,
        tracks: playlist.tracks
      };
    } catch (error) {
      throw new Error(String(error));
    }
    
    try {
      // Get YouTube Music category ID (using cached value if available)
      const youtubeMusicCategoryId: string = await youtubeGetMusicCategoryId();
      
      // Process tracks in batches to optimize API usage
      const BATCH_SIZE = 5; // Reduced batch size for better reliability
      const allVideoIds: string[] = [];
      
      // Process tracks in batches
      for (let i = 0; i < playlist.tracks.length; i += BATCH_SIZE) {
        const batchTracks = playlist.tracks.slice(i, i + BATCH_SIZE);
        const searchPromises = batchTracks.map(track => {
          const query = encodeURIComponent(`${track.name} ${track.artist}`);
          return retryableFetch(() => 
            fetch('https://www.googleapis.com/youtube/v3/search' +
                 '?part=snippet' +
                 `&q=${query}` +
                 '&type=video' +
                 `&videoCategoryId=${youtubeMusicCategoryId}` +
                 '&maxResults=1', {
              headers: {
                Authorization: `Bearer ${ProvidersConfig.getAccessToken(this.prefix)}`
              }
            })
          ).then(res => res.json());
        });
        
        // Wait for all search requests in this batch to complete
        const searchResults = await Promise.all(searchPromises);
        
        // Extract video IDs from search results
        searchResults.forEach(result => {
          if (result.items && result.items.length > 0) {
            allVideoIds.push(result.items[0].id.videoId);
          }
        });
        
        // Pause between batches to avoid rate limiting
        if (i + BATCH_SIZE < playlist.tracks.length) {
          await new Promise(resolve => setTimeout(resolve, 2000)); // Increased delay
        }
      }
      
      // Add videos to playlist in batches
      if (allVideoIds.length > 0) {
        // First, get existing playlist items to avoid duplicates
        const existingItems = new Set<string>();
        let nextPageToken: string | undefined;
        
        do {
          const pageUrl = 'https://www.googleapis.com/youtube/v3/playlistItems' +
                        '?part=snippet' +
                        `&playlistId=${encodeURIComponent(newPlaylist?.id || '')}` +
                        '&maxResults=50' +
                        (nextPageToken ? `&pageToken=${nextPageToken}` : '');
          
          const response = await retryableFetch(() => 
            fetch(pageUrl, {
              method: 'GET',
              headers: {
                Authorization: `Bearer ${ProvidersConfig.getAccessToken(this.prefix)}`
              }
            })
          );
          
          const data = await response.json();
          data.items.forEach((item: { snippet: { resourceId: { videoId: string } } }) => {
            existingItems.add(item.snippet.resourceId.videoId);
          });
          
          nextPageToken = data.nextPageToken;
        } while (nextPageToken);
        
        // Filter out duplicate video IDs
        const uniqueVideoIds = allVideoIds.filter(id => !existingItems.has(id));
        
        // Add unique videos in smaller batches
        for (let i = 0; i < uniqueVideoIds.length; i += BATCH_SIZE) {
          const batchVideoIds = uniqueVideoIds.slice(i, i + BATCH_SIZE);
          const addPromises = batchVideoIds.map(videoId => {
            return retryableFetch(() => 
              fetch('https://www.googleapis.com/youtube/v3/playlistItems?part=snippet', {
                method: 'POST',
                headers: {
                  Authorization: `Bearer ${ProvidersConfig.getAccessToken(this.prefix)}`,
                  'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                  snippet: {
                    playlistId: newPlaylist?.id,
                    resourceId: {
                      kind: 'youtube#video',
                      videoId
                    }
                  }
                })
              })
            ).then(res => res.json());
          });
          
          try {
            // Wait for all add requests in this batch to complete
            await Promise.all(addPromises);
          } catch (error) {
            // Log error but continue with next batch
            console.warn(`Error adding batch to playlist: ${error}`);
          }
          
          // Pause between batches to avoid rate limiting
          if (i + BATCH_SIZE < uniqueVideoIds.length) {
            await new Promise(resolve => setTimeout(resolve, 2000)); // Increased delay
          }
        }
      }
      
      return playlist;
    } catch (error) {
      throw new Error(`${String(error)} (processing YouTube Music tracks failed)`);
    }
  }
};

export default YoutubeMusic;
