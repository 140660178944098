import { type Track } from '../../api/providers/Types';
import { useState, useRef } from 'react';
import './CsvImport.css';

interface CsvImportProps {
  onTracksLoaded: (tracks: Track[]) => void;
}

const CsvImport = ({ onTracksLoaded }: CsvImportProps): JSX.Element => {
  const [isDragging, setIsDragging] = useState(false);
  const [csvText, setCsvText] = useState('');
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const parseCsvText = (text: string): Track[] => {
    const lines = text.split('\n');
    const tracks: Track[] = [];

    lines.forEach((line) => {
      const [name, artist] = line.split(';').map((s) => s.trim());
      if (name && artist) {
        tracks.push({ name, artist });
      }
    });

    return tracks;
  };

  const handleTextChange = (e: React.ChangeEvent<HTMLTextAreaElement>): void => {
    const text = e.target.value;
    setCsvText(text);
    const tracks = parseCsvText(text);
    onTracksLoaded(tracks);
  };

  const handleDragEnter = (e: React.DragEvent): void => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  };

  const handleDragLeave = (e: React.DragEvent): void => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  };

  const handleDragOver = (e: React.DragEvent): void => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e: React.DragEvent): void => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);

    const file = e.dataTransfer.files[0];
    if (file && file.type === 'text/csv') {
      const reader = new FileReader();
      reader.onload = (event) => {
        const text = event.target?.result as string;
        setCsvText(text);
        const tracks = parseCsvText(text);
        onTracksLoaded(tracks);
      };
      reader.readAsText(file);
    } else {
      alert('Please drop a CSV file');
    }
  };

  return (
    <div 
      className={`csv-import ${isDragging ? 'dragging' : ''}`}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
    >
      <textarea
        ref={textareaRef}
        value={csvText}
        onChange={handleTextChange}
        placeholder="Paste your CSV here or drag and drop a file&#10;Format: name;artist (one track per line)"
        aria-label="CSV input"
      />
      <p className="csv-format">Format: name;artist (one track per line)</p>
    </div>
  );
};

export default CsvImport; 